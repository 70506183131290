// import interceptors from 'cat-api/axios';
import interceptors, { WithAxiosWrapper } from 'cat-components/layout/withAxios';
import { GlobalProvider } from 'cat-context/index';
import { UserProvider } from 'cat-context/userContext';
import RouteGuard from 'cat-utils/RouteGuard';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'cat-styles/globals.css';
import 'cat-styles/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import 'swiper/css';

function MyApp({ Component, pageProps }) {
  interceptors;

  return (
    <GlobalProvider>
      <UserProvider>
        <RouteGuard {...pageProps}>
          <Component {...pageProps} />
        </RouteGuard>
      </UserProvider>
    </GlobalProvider>
  );
}

export default MyApp;
