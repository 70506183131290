import useAxios from 'axios-hooks';
import configs from 'cat-api/configs';
import { GlobalContext } from 'cat-context/index';
import { useHistory } from 'cat-hooks/useHistory';
import useToast from 'hooks/useToast';
import isEmpty from 'lodash/isEmpty';
import { createContext, useCallback, useContext, useEffect, useMemo } from 'react';

const UserContext = createContext({
  userInfo: {},
  handleLogin: () => {},
  handleLogout: () => {},
  userLoading: false,
  isAuth: false,
});

const UserProvider = ({ children }) => {
  const toast = useToast();
  const { globalState, handleUpdateGlobalState } = useContext(GlobalContext);
  const { handleSyncHistory } = useHistory();
  const isAuth = globalState.localAuth;

  const [{ data: userInfo, error: errorInfo }, executeGetUserInfo] = useAxios(
    {
      url: `/users/my-info`,
      method: 'GET',
    },
    {
      manual: true,
    }
  );

  const [{ data: dataLogout, loading: loadingLogout }, executeLogout] = useAxios(
    { url: `/${configs.users.logout}`, method: 'GET' },
    { manual: true }
  );

  const [{ data: dataLogin, loading: loadingLogin }, executeLogin] = useAxios(
    { url: '/users/login', method: 'POST' },
    { manual: true }
  );

  const handleLogout = useCallback(async () => {
    try {
      console.log('[LOGOUT]');
      await executeLogout();
      handleUpdateGlobalState('localAuth', false);
    } catch (error) {
      console.log('[ERROR]', error.message);
      toast.error(error.response.data.message || 'Lỗi không xác định');
    }
  });

  const handleLogin = useCallback(
    async (formData) => {
      try {
        console.log('[LOGIN]');
        await executeLogin({ data: formData });
        await handleSyncHistory();
        handleUpdateGlobalState('localAuth', true);
      } catch (error) {
        console.log('[ERROR]', error.message);
        toast.error(error.response.data.message || 'Lỗi không xác định');
      }
    },
    [handleSyncHistory]
  );

  const handleGetUserInfo = async () => {
    try {
      if (process.browser) {
        await executeGetUserInfo();
        handleUpdateGlobalState('localAuth', true);
      }
    } catch (error) {
      console.log('[ERROR]', error.message);
    }
  };

  useEffect(() => {
    if (!isEmpty(dataLogin)) {
      toast.success(dataLogin.message || 'Đăng nhập thành công');
    }
  }, [dataLogin]);

  useEffect(() => {
    if (!isEmpty(dataLogout)) {
      toast.success(dataLogout.message || 'Đăng xuất thành công');
    }
  }, [dataLogout]);

  useEffect(() => {
    if (!isAuth) return;
    handleGetUserInfo();
  }, [isAuth]);

  const contextValue = useMemo(() => {
    return {
      userInfo: errorInfo ? {} : userInfo?.user_info || {},
      handleLogin,
      handleLogout,
      handleGetUserInfo
    };
  }, [userInfo, errorInfo, handleLogin]);

  return (
    <UserContext.Provider
      value={{ ...contextValue, userLoading: loadingLogout || loadingLogin, isAuth }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
