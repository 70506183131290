import { useCallback, useMemo } from 'react';
import { toast, ToastContainer } from 'react-toastify';

const config = {
  position: 'top-left',
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};
const useToast = () => {
  const success = useCallback((message) => toast.success(message, config), []);

  const error = useCallback((message) => toast.error(message, config), []);

  const warning = useCallback((message) => toast.error(message, config), []);

  const info = useCallback((message) => toast.info(message, config), []);

  return useMemo(() => ({ success, error, warning, info, ToastContainer }), []);
};

export default useToast;
