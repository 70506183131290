import { defaultStore, localStorageKey } from 'cat-configs/constants';
import { createContext, useEffect, useMemo, useState } from 'react';

const isClient = typeof window !== 'undefined';

const GlobalContext = createContext({});

const GlobalProvider = ({ children }) => {
  const [globalState, setGlobalState] = useState(() => {
    return isClient
      ? JSON.parse(localStorage.getItem(localStorageKey)) || defaultStore
      : defaultStore;
  });

  const handleUpdateGlobalState = (key, value) => setGlobalState((prev) => ({ ...prev, [key]: value }));

  const contextValue = useMemo(() => {
    return {
      globalState,
      handleUpdateGlobalState,
    };
  }, [globalState]);

  useEffect(() => {
    isClient && localStorage.setItem(localStorageKey, JSON.stringify(globalState));
  }, [globalState]);

  return <GlobalContext.Provider value={contextValue}>{children}</GlobalContext.Provider>;
};

export { GlobalContext, GlobalProvider };
