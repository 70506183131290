import Axios from 'axios';
import { configure } from 'axios-hooks';
import { GlobalContext } from 'cat-context/index';
import { useContext, useEffect, useState } from 'react';

const axios = Axios.create({
  baseURL: process.env.URL_API,
  withCredentials: true,
});

export const WithAxiosWrapper = ({ children }) => {
  const [isSet, setIsSet] = useState(false);
  const { handleUpdateGlobalState } = useContext(GlobalContext);

  useEffect(() => {
    const resInterceptor = (response) => {
      return response;
    };
    const errInterceptor = (error) => {
      if (error.response.status === 401) {
        handleUpdateGlobalState('localAuth', false);
      }
      return Promise.reject(error);
    };
    const interceptor = axios.interceptors.response.use(resInterceptor, errInterceptor);
    configure({ axios });
    setIsSet(true);
    return () => axios.interceptors.response.eject(interceptor);
  }, [handleUpdateGlobalState]);

  return isSet && children;
};

export default axios;
