module.exports = {
  url: process.env.URL_API,
  comics: {
    home: 'comics/cat',
    details: 'comics/',
    category_list: 'comics/category/:slug/list',
    category: 'comics/category/',
    views: 'comics/views/',
    root: 'comics',
  },
  categories: {
    list: 'category',
    count: 'category/count',
    details: 'category/',
    feature_comic: 'category/:slug/feature-comic',
  },
  chapter: {
    details: 'chapter/',
  },
  system: {
    bug_report: 'system/error-report',
  },
  proxy: {
    icq: 'proxy/icq/',
    leech: 'proxy/leech/',
  },
  users: {
    register: 'users/register',
    login: 'users/login',
    update_user: 'users/update-user',
    change_password: 'users/change-password',
    forgot_password: 'users/forgot-password',
    upload_avatar: 'users/upload-avatar',
    bz: 'users/bz',
    logout: 'users/logout',
    read: 'users/read',
    favorite: 'users/favorite'
  },
  teams: {
    root: 'teams',
  },
  bookshelves: {
    comic: 'bookshelf/comic',
  },
  comments: {
    root: 'comments',
  },
  comicview: {
    daily: 'comic-views/daily',
    weekly: 'comic-views/weekly',
    monthly: 'comic-views/monthly',
  },
  like: {
    root: 'likes',
  },
  issue: {
    root: 'issue',
  }
};
