import useAxios from 'axios-hooks';
import configs from 'cat-api/configs';
import { GlobalContext } from 'cat-context/index';
import { UserContext } from 'cat-context/userContext';
import { useCallback, useContext } from 'react';
import useToast from './useToast';

const convertToHistory = (localHistory, chapter_info) => {
  const comicData = chapter_info.comicId;
  const comicId = {
    _id: comicData._id,
    category: comicData.category,
    views: comicData.views,
    title: comicData.title,
    author: comicData.author,
    slug: comicData.slug,
    cover: comicData.cover,
  };
  const chapterId = {
    chapterIndex: chapter_info.chapterIndex,
    chapterTitle: chapter_info.chapterTitle,
    _id: chapter_info._id,
    slug: chapter_info.slug,
    fakeSlug: chapter_info.fakeSlug,
  };
  const createdAt = new Date().toISOString();
  const readComicIndex = localHistory.findIndex((comic) => comic.comicId._id == comicData._id);
  if (readComicIndex > -1) {
    localHistory.splice(readComicIndex, 1);
  }
  localHistory.unshift({
    comicId,
    chapterId,
    createdAt,
  });

  return localHistory;
};

export const useHistory = () => {
  const { globalState, handleUpdateGlobalState } = useContext(GlobalContext);
  const { history: localHistory } = globalState;
  const { isAuth } = useContext(UserContext);
  const toast = useToast();
  const [, executeSyncHistory] = useAxios(
    { url: `/${configs.users.read}`, method: 'POST' },
    { manual: true }
  );
  const [, executeDeleteComicRead] = useAxios({}, { manual: true });

  const handleSyncHistory = useCallback(
    async (bodyData, chapter_info) => {
      const { data } = await executeSyncHistory({ data: { localHistory, ...bodyData } });
      if (data.data.read) {
        handleUpdateGlobalState('history', data.data.read);
      } else {
        handleUpdateGlobalState('history', convertToHistory(localHistory, chapter_info));
      }
    },
    [handleUpdateGlobalState, localHistory]
  );

  const handleDeteleHistory = useCallback(
    async (comicId) => {
      if (isAuth) {
        const { data: deleteComicRead } = await executeDeleteComicRead({
          url: `/${configs.users.read}/${comicId}`,
          method: 'DELETE',
        });
        if (deleteComicRead && deleteComicRead.code === 200) {
          toast.success(deleteComicRead.data.message);
        }
      } else {
        toast.success('Xóa thành công!');
      }
      handleUpdateGlobalState(
        'history',
        localHistory.filter((item) => item.comicId._id !== comicId)
      );
    },
    [isAuth, localHistory]
  );

  return { handleSyncHistory, syncHistory: localHistory, handleDeteleHistory };
};
