import { DEBUG_MODE_KEY } from 'cat-configs/constants';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect } from 'react';

const NotFound = dynamic(() => import('pages/404'));
const ServerError = dynamic(() => import('pages/500'));

const RouteGuard = (props) => {
  const { children, notFound = false, serverError = false, ...rest } = props;
  const { query } = useRouter();

  if (notFound) {
    return <NotFound />;
  } else if (serverError) {
    return <ServerError {...rest} />;
  }

  useEffect(() => {
    if (query.debug === 'true' || query.debug === 'false') {
      localStorage.setItem(DEBUG_MODE_KEY, query.debug);
    }
  }, [query]);

  if (typeof window !== 'undefined' && localStorage.getItem(DEBUG_MODE_KEY) === 'true') {
    return (
      <>
        {children}
        <Script
          src="//cdn.jsdelivr.net/npm/eruda"
          onLoad={(e) => window.eruda.init()}
          onError={(e) => {
            console.error('Script failed to load', e);
          }}
        />
      </>
    );
  }
  return <>{children}</>;
};

export default RouteGuard;
