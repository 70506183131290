export const TABS = {
  mostViewed: {
    DAY_VIEW: 'daily',
    WEEK_VIEW: 'weekly',
    MONTH_VIEW: 'monthly',
  },
  lastedUpdate: {
    CHAPTER: 'chapter',
    VOLUME: 'volume',
  },
  homeComment: {
    NEWEST_COMMENT: 'newest-comment',
    TOP_COMMENT: 'top-comment',
  },
};

export const COMIC_SLIDER = {
  TRENDING: 'trending',
  RECOMENDED: 'recommended',
  COMPLETED: 'completed',
  NEW: 'new',
  READING: 'reading',
};

export const MODAL_SIDEBAR = {
  MENU_MOBILE: 'menu-mobile',
  LOGIN_FORM: 'login-form',
  REGISTER_FORM: 'register-form',
  FORGOT_PASSWORD: 'forgot-password',
  READING_COMMENT: 'reading-comment',
  READING_SETTING: 'reading-setting',
  CREATE_ERROR_REPORT: 'create-error-report',
  SEARCH_MOBILE: 'search-mobile',
};

export const READING_DROPDOWN = {
  BY_CHAPTER: 'by-chapter',
  LANGUAGE: 'language',
  CHAPTER: 'chapter',
  VOLUME: 'volume',
  SERVER_SOURCE: 'sources',
  READING_MODE: 'reading-mode',
  READING_DIRECTION: 'reading-direction',
  READING_QUALITY: 'reading-qualiry',
  FONT_SELECT: 'reading-font',
  FONT_SIZE: 'reading-fontsize',
  LINE_SPACING: 'reading-line-spacing',
  BACKGROUND_SELECT: 'reading-background',
};

export const TYPE = {
  NOVEL: 'novel',
  COMIC: 'comic',
};

export const TYPE_SLIDE = {
  COMIC: 'comic',
  CUSTOM: 'custom',
};

export const LOCK_CHAPTER = {
  NON_LOCK: 'none',
  LOCK_BY_PASSWORD: 'password',
  LOCK_BY_BOT: 'bot',
  LOCK_BY_BUY: 'buy',
};

export const LABEL_COMICS_SLIDE = {
  new_comics: 'New Comics',
  updated_comics: 'Updated Comics',
  top_comics: 'Top Comics',
  recommend_comics: 'Recommend Comics',
  read: 'Continue Reading',
};

export const SOCIAL = {
  discord: 'https://discord.gg/WxnWRGCZbR',
  fb_page: 'https://www.facebook.com/comicaholicvn',
  fb_group: 'https://www.facebook.com/groups/312651036700737',
};

export const TIME_UPDATE_VIEW = 15000;

export const BADGE_COMIC = {
  hot: 'Hot',
  new: 'New',
  blockbuster: 'Bom tấn',
  superArtist: 'Art siêu phẩm',
};

export const defaultSettingsNovel = {
  font: 'inherit',
  fontSize: 14,
  lineSpacing: 120,
  background: '#2c2c2c',
};

export const defaultStore = {
  localAuth: false,
  settings: {
    [TYPE.COMIC]: {},
    [TYPE.NOVEL]: defaultSettingsNovel,
  },
  history: [],
};

export const localStorageKey = process.env.STORE_WEB;

export const dropdownHeader = [
  {
    key: 'profile',
    title: 'Profile',
    icon: 'fa-user',
    url: '/user',
  },
  {
    key: 'readings',
    title: 'Continue Reading',
    icon: 'fa-glasses',
    url: '/user/readings',
  },
  {
    key: 'favorites',
    title: 'Favorite Comic',
    icon: 'fa-bookmark',
    url: '/user/truyen-yeu-thich',
  },
  {
    key: 'notifications',
    title: 'Notifications',
    icon: 'fa-bell',
    url: '/user/thong-bao',
  },
];

export const DEBUG_MODE_KEY = 'comicaholic.com.debug';
export const SITE_NAME = process.env.SITE_NAME;
export const URL_WEB = process.env.URL_WEB;
